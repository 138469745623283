import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from '../auth/_services/auth.service';

export const retryCount = 3;

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

    constructor (private authenticationService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => {
            if ([401].includes(error.status) && !request.url.endsWith("/refresh-token")) {
                return this.reAuthenticate().pipe(
                    switchMap(() => {
                        const auth = this.authenticationService.getAuthFromLocalStorage();
                        let modifiedReq = request.clone({ headers: request.headers.set('Authorization', `Bearer ${auth.token}`), });
                        return next.handle(modifiedReq)
                    })
                )
            }
            else if ([401].includes(error.status) && request.url.endsWith("/refresh-token")) {
                this.authenticationService.logout();
            }
            else if ([403].includes(error.status)) {
                this.authenticationService.logout();
            }
            else {
                return throwError(error);
            }
        })
        )
    }

    reAuthenticate(): Observable<any> {
        return this.authenticationService.refreshToken().pipe(
            catchError(error => {
                this.authenticationService.logout();
                return throwError(error);
            })
        );
    }
}