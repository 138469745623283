import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable()
export class AccountService {
    private subject = new Subject<any>();
    titleSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        'Dashboard'
    );
    sendData(message: any) {
        // this.subject.next(message);
        this.titleSubject.next(message);
    }

    // clearData() {
    //     this.subject.next();
    // }

    // getData(): Observable<any> {
    //     return this.subject.asObservable();
    // }

    // setTitle(title: string = '') {
    //     this.titleSubject.next(title);
    //   }
}