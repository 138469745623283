import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor (private router: Router, private authService: AuthService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authService.currentUserValue;
    if (currentUser == undefined) {
      this.authService.logout();
      return false;
    }
    else if (state.url == "/admin/manage-accounts" && currentUser.role != 'SuperAdmin') {
      this.router.navigate(['/error/error-1'], { queryParams: {} });
      return false;
    }
    else if (state.url == "/admin/manage-users" && currentUser.role == 'ViewOnly') {
      this.router.navigate(['/error/error-1'], { queryParams: {} });
      return false;
    }
    else if (state.url == "/settings/webservice-settings" && currentUser.role == 'ViewOnly') {
      this.router.navigate(['/error/error-1'], { queryParams: {} });
      return false;
    }
    else if (currentUser) {
      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page with the return url
    else {
      this.authService.logout();
      // this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
