import { Injectable, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription, throwError } from 'rxjs';
import { map, catchError, switchMap, finalize } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { BaseUrlService } from 'src/app/shared/services/base-url.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as crypto from 'crypto-js';
@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  // private fields
  private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/
  private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;

  // public fields
  currentUser$: Observable<UserModel>;
  isLoading$: Observable<boolean>;
  currentUserSubject: BehaviorSubject<UserModel>;
  isLoadingSubject: BehaviorSubject<boolean>;
  auth: AuthModel
  //RoleName
  roleKey = "RoleLabel";
  emailaddressKey = "Email";
  get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  set currentUserValue(user: UserModel) {
    this.currentUserSubject.next(user);
  }

  constructor (
    private baseUrl: BaseUrlService,
    private router: Router,
    private http: HttpClient
  ) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.isLoading$ = this.isLoadingSubject.asObservable();
    // const subscr = this.getUserByToken().subscribe();
    // this.unsubscribe.push(subscr);
  }

  // public methods
  login(email: string, password: string): Observable<any> {
    this.isLoadingSubject.next(true);
    var loginobject = {
      email: this.EncriptPassword(email),
      password: this.EncriptPassword(password)
    }
    var url = this.baseUrl.BaseUrl() + '/connect/token';
    return this.http.post(url, loginobject).pipe(
      map(data => {
        const result = this.setAuthFromLocalStorage(data);
        this.startRefreshTokenTimer();
        return result;
      }),
      switchMap(() => this.getUserByToken()),
      catchError((err) => {
        console.error('err', err);
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  async logout() {
    const auth = this.getAuthFromLocalStorage();
    if (auth != null) {
      const httpHeaders = new HttpHeaders({
        Authorization: `Bearer ${auth.token}`,
        refreshToken: auth.refreshToken
      });
      var url = this.baseUrl.BaseUrl() + '/connect/revoke-token';
      await this.http.post<any>(`${url}`, {}, { headers: httpHeaders }).subscribe();
      this.stopRefreshTokenTimer();
      this.currentUserSubject.next(null);
      localStorage.removeItem(this.authLocalStorageToken);
      this.router.navigate(['/auth/login']);
    }
    else {
      this.router.navigate(['/auth/login'], {
        queryParams: {},
      });
    }
  }

  forgotPassword(email: string): Observable<boolean> {
    var url = this.baseUrl.BaseUrl() + '/api/Users/SendPassword';
    var object = {
      email: this.EncriptPassword(email)
    }
    this.isLoadingSubject.next(true);
    return this.http.post(url, object).pipe(
      map(data => {
        return true;
      }),
      catchError((err) => {
        return of(undefined);
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  refreshToken() {
    const auth = this.getAuthFromLocalStorage();
    if (auth != null) {
      var object = {
        refreshToken: auth.refreshToken,
      }
      this.isLoadingSubject.next(true);
      var url = this.baseUrl.BaseUrl() + '/connect/refresh-token';
      return this.http.post<any>(`${url}`, {}, { headers: object })
        .pipe(map((user) => {
          // this.userSubject.next(user);
          localStorage.removeItem(this.authLocalStorageToken);
          this.setAuthFromLocalStorage(user);
          this.startRefreshTokenTimer();
          return user;
        }), catchError(error => {
          return throwError(error);
        }),
          switchMap(() => this.getUserByToken()),
          finalize(() => this.isLoadingSubject.next(false)));
    }
    else {
      return of(undefined);
    }
  }

  getUserByToken(): any {
    const auth = this.getAuthFromLocalStorage();
    if (!auth || !auth.token) {
      return of(undefined);
    }
    this.isLoadingSubject.next(true);
    return this.getDecodedAccessToken(auth).pipe(
      map((user: UserModel) => {
        if (user) {
          this.currentUserSubject = new BehaviorSubject<UserModel>(user);
        } else {
          this.logout();
        }
        return user;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  getDecodedAccessToken(res): any {
    try {
      // const decodedata = jwt_decode(token);
      const User = {
        accountId: res.accountId,
        accountName: res.accountName,
        role: res.roleName,
        roleLabel: res.roleLabel,
        emailaddress: res.email,
      }
      return of(User)
    }
    catch (Error) {
      return of(undefined);
    }
  }


  private setAuthFromLocalStorage(auth): boolean {
    // store auth token/refreshToken/epiresIn in local storage to keep user logged in between page refreshes
    if (auth && auth.token) {
      localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
      return true;
    }
    return false;
  }

  public getAuthFromLocalStorage() {
    try {
      const authData = JSON.parse(
        localStorage.getItem(this.authLocalStorageToken)
      );
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  ngOnDestroy() {
    this.unsubscribe.forEach((sb) => sb.unsubscribe());
  }

  private refreshTokenTimeout;

  private startRefreshTokenTimer() {
    const auth = this.getAuthFromLocalStorage();
    if (auth != null) {
      // parse json object from base64 encoded jwt token
      const jwtToken = JSON.parse(atob(auth.token.split('.')[1]));

      const expires = new Date(jwtToken.exp * 1000);

      // set a timeout to refresh the token 59 minute before it expires
      // const timeout = expires.getTime() - Date.now() - ((600 * 5000) + 540000);

      // set a timeout to refresh the token 10 minute before it expires
      const timeout = expires.getTime() - Date.now() - (600 * 1000);
      this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  EncriptPassword(password: string) {

    var key = crypto.enc.Utf8.parse('7061737323313233');
    var iv = crypto.enc.Utf8.parse('7061737323313233');
    var encrypted = crypto.AES.encrypt(crypto.enc.Utf8.parse(password), key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: crypto.mode.CBC,
        padding: crypto.pad.Pkcs7
      });
    return encrypted.toString();
  }
}
